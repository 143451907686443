import {
  createCustomerDetails,
  createBillingInfo,
  createAppliance,
  createCoveredProperty,
  createCustomerProductPreferences,
  createMarketingPermissions,
  createRepairs,
  createTracking,
  getExcessLevel,
  createRewards,
} from './SignupFactory';

import { Store } from '@src/store/store';
import { SignupDto } from '@src/generated/SignupDto';
import { DeepPartial } from '@src/types/DeepPartial';
import { Journey } from '@src/types/Journey';
import { IQuote, ProductCode } from '@src/types/Quote';

export type CreateSignupDtoValueV2 = {
  store: Omit<Store, 'evCharger' | 'epdEligibility'>;
  quote: IQuote;
  coverTypeId: number;
};

export function createSignupDtoV2({
  store,
  quote,
  coverTypeId,
}: CreateSignupDtoValueV2): DeepPartial<SignupDto> {
  const {
    accountId,
    promoCode,
    boilerDetails,
    paymentDetails,
    marketingPermissions,
    sensitivePaymentDetails,
    repair,
    personalDetails,
    isCreditEligible,
    creditType,
    utmTracking,
    journey,
  } = store;

  const customerDetails = createCustomerDetails(personalDetails, accountId);
  const isCreditRewardSale = Boolean(
    accountId &&
      isCreditEligible &&
      journey !== Journey.Abs &&
      journey !== Journey.InsuranceHighExcess &&
      journey !== Journey.HomeRecover
  );
  const isEvCharger = quote.product === ProductCode.EV_CHARGER_COVER;
  const isEpdCover = quote.product === ProductCode.EPD_COVER;

  return {
    signupType: 'Web',
    customerDetails,
    billingInfo: createBillingInfo(
      sensitivePaymentDetails,
      paymentDetails.accountOwner,
      customerDetails.address
    ),
    product: {
      // we dont want to track the appliance in the case the user is buying ev charger or epd cover.
      appliance: isEvCharger || isEpdCover ? null : createAppliance(boilerDetails),
      productDetails: {
        productName: quote.product,
        promoCode,
        excessLevel: getExcessLevel(quote.excess),
        coverTypeId,
      },
      coveredProperty: createCoveredProperty(boilerDetails, customerDetails.address, {
        isEvCharger,
        isEpdCover,
      }),
      customerProductPreferences: createCustomerProductPreferences(paymentDetails),
      marketingPermissions: marketingPermissions
        ? createMarketingPermissions(marketingPermissions)
        : undefined,
      repairs: createRepairs(repair),
    },
    trackingData: createTracking({ isCreditRewardSale, tracking: utmTracking, creditType }),
    rewards: createRewards({ isCreditRewardSale }),
  };
}
