import { Field, Form, Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import {
  Accordion,
  AccordionGroup,
  Checkbox,
  ErrorNotification,
  Heading3,
  InfoNotification,
  Li,
  Margin,
  NumberField,
  P,
  Stack,
  Strong,
  TextField,
  TextLink,
  Ul,
} from '@ovotech/element';
import { WindowLocation } from '@reach/router';
import { useAtomValue } from 'jotai';

import { copyNew } from './copy';
import { FormData } from './PaymentDetailsPage';

import { BasicPage, TopSummary } from '@components';
import { ScrollToFieldError } from '@components/ScrollToFieldError';
import { TrackFormErrors } from '@components/TrackFormErrors';
import {
  ROUTE_BOILER,
  ROUTE_EPD_DETAILS,
  ROUTE_EV_DETAILS,
  ROUTE_HOME_RECOVER_BOILER,
} from '@constants/routes';
import { AutoRenewalOption, MixpanelEvents } from '@entities/enums';
import { PRIVACY_POLICY } from '@constants/OVOUrls';
import { useCustomMixpanel } from '@services/Mixpanel';
import { getEnergyCreditTermsURL } from '@utils/EnergyCredit';
import { isBrowser } from '@utils/isBrowser';
import { getStepTitle } from '@utils/getStepTitle';
import { FormContainer, MarginDivider } from '@src/Styles/Common.Styles';
import { useMediaIs } from '@hooks/useMediaIs';
import CustomCard from '@components/CustomCard';
import { SortCodeField } from '@src/modules/PaymentDetails/components';
import { mqMediumUp } from '@utils/mediaQuery';
import { restrictInputLengthForBankDetails } from '@utils/restrictInputLengthForBankDetails';
import { journeyAtom, signupCompleteAtom } from '@src/store/store';
import useEvCharger from '@hooks/useEvCharger';
import useEpdCover from '@hooks/useEpdCover';
import { Journey } from '@src/types/Journey';
import { DocumentType, IDocument, ProductName } from '@src/types/Quote';
import { BasketNavigationButtonsV2 } from '@components/BasketV2/BasketNavigationsButtons';
import { BasketV2 } from '@components/BasketV2/BasketV2';

const StyledInfoNotification = styled(InfoNotification)`
  ${({ theme: { semantic } }) => `
    div a span {
      color: ${semantic.message.link}
    };
    & > div {
      border: 1px solid ${semantic.info.surface};
    }
  `}
`;

const StyledErrorNotification = styled(ErrorNotification)`
  ${({ theme: { semantic } }) => `
    & > div {
    border: 1px solid ${semantic.error.surface};
    }
  `}
`;

const BankInfoFieldWrapper = styled.div`
  max-width: 100%;
  ${mqMediumUp(`max-width: 190px;`)}
`;

const AccordionWrapper = styled.div`
  ${({ theme: { semantic } }) => `
    background-color: ${semantic.surface.base};
  `}
`;

export interface NewPaymentDetailsProps {
  formData: FormData;
  location?: WindowLocation;
  isHomeRecover?: boolean;
  isAbs?: boolean;
  isLoggedIn: boolean;
  error: boolean;
  badBankDetails: boolean;
  productName?: ProductName;
  documents?: IDocument[];

  onSubmit(values: FormData, { setSubmitting }: FormikHelpers<FormData>): void;

  validate(values: FormData): FormikErrors<FormData>;
}

export const NewPaymentDetails = ({ ...props }: NewPaymentDetailsProps) => {
  const {
    onSubmit,
    validate,
    error,
    badBankDetails,
    formData,
    isHomeRecover,
    isLoggedIn,
    isAbs,
    productName,
    documents,
  } = props;

  const selectedCoverTypeText = productName ?? 'boiler cover';

  const documentUrl = documents?.reduce(
    (documentsObj: Record<DocumentType, string>, { type, url }) => ({
      ...documentsObj,
      [type]: url,
    }),
    {} as Record<DocumentType, string>
  );

  const journey = useAtomValue(journeyAtom);
  const signupComplete = useAtomValue(signupCompleteAtom);

  const mixpanel = useCustomMixpanel();

  const isMobile = useMediaIs('mobile', 'max');

  const {
    policyRenewal,
    accountHolderConfirmation,
    paymentFlow,
    cancellingDuringFourteenDaysPeriod,
    cancellingAfterFouteenDaysPeriod,
    links,
    receiveDocumentation,
    unableToLoadError,
    directDebitGuarantee,
    directDebitInstruction,
  } = copyNew(productName);

  const { evChargerSelected } = useEvCharger();
  const { epdCoverSelected } = useEpdCover();

  const getBackButtonText = () => {
    if (evChargerSelected || epdCoverSelected) {
      return 'Back to Personal details';
    }

    return 'Back to Boiler details';
  };

  const backButtonText = getBackButtonText();

  const getAutoRenewalAccordianText = () => {
    if (evChargerSelected) {
      return 'By auto-renewing your contract with us you help keep yourself covered against the cost of issues with your EV Charger 24/7 for another year.';
    }
    if (epdCoverSelected) {
      return 'Auto-renewal means your cover continues after your initial 12 month contract ends. Your electrics, plumbing and drainage will be covered for another year.';
    }
    return 'By auto-renewing your contract with us you help keep yourself covered against the cost of boiler breakdowns and home emergencies 24/7 for another year.';
  };

  const autoRenewalAccordianText = getAutoRenewalAccordianText();

  const fieldsInOrder: (keyof FormData)[] = [
    'nameOnAccount',
    'sortCode',
    'accountNumber',
    'accountOwner',
    'termsAndConditions',
    'homeRecoverTerms',
  ];

  const title = 'Payment details';
  const aboveHeading = getStepTitle(props.location);

  const handleBackButtonClick = () => {
    if (evChargerSelected) {
      navigate(ROUTE_EV_DETAILS);
      return;
    }
    if (epdCoverSelected) {
      navigate(ROUTE_EPD_DETAILS);
      return;
    }
    if (isHomeRecover) {
      navigate(`${ROUTE_HOME_RECOVER_BOILER}/${window.location.search}`);
      return;
    }
    navigate(`${ROUTE_BOILER}/${window.location.search}`);
  };

  if (isBrowser() && badBankDetails) {
    window.scrollTo(0, 0);
  }

  return (
    <>
      {props.location && (
        <BasicPage
          pageName="paymentDetails"
          location={props.location}
          shouldIncludeFootnotes={true}
          warnOnLeaving
        >
          <TopSummary {...{ title, aboveHeading }} />

          <Formik initialValues={{ ...formData }} onSubmit={onSubmit} validate={validate}>
            {({
              handleChange,
              setFieldValue,
              isSubmitting,
              errors,
              values,
              submitCount,
            }: FormikProps<FormData>) => {
              const hasTriedToSubmit = submitCount > 0;
              return (
                <Form data-testid="payment-form" role="form">
                  <ScrollToFieldError<FormData> fieldsInOrder={fieldsInOrder} />
                  <TrackFormErrors errors={errors} trackErrors={hasTriedToSubmit} />
                  <BasicPage.Content>
                    <BasicPage.Left>
                      <CustomCard>
                        <Stack spaceBetween={8}>
                          <Stack spaceBetween={[4, 6]}>
                            {error && !badBankDetails && (
                              <StyledErrorNotification
                                id="payment-details-page-error"
                                title="Error"
                                nonce={undefined}
                              >
                                <P>{unableToLoadError}</P>
                              </StyledErrorNotification>
                            )}
                            {badBankDetails && (
                              <div data-testid="summary-card-error">
                                <StyledErrorNotification
                                  id="summary-card-error"
                                  title="Error"
                                  nonce={undefined}
                                >
                                  Looks like there was a problem with your payment details. Please
                                  check that they are correct.
                                </StyledErrorNotification>
                              </div>
                            )}
                            <FormContainer>
                              <Stack spaceBetween={[4, 6]}>
                                <TextField
                                  id="nameOnAccount"
                                  name="nameOnAccount"
                                  label="Account holder name"
                                  value={values.nameOnAccount}
                                  onChange={handleChange}
                                  error={hasTriedToSubmit && errors.nameOnAccount}
                                  data-testid="account-name"
                                  fullWidth
                                />
                                <BankInfoFieldWrapper>
                                  <NumberField
                                    id="accountNumber"
                                    name="accountNumber"
                                    label="Account number"
                                    value={values.accountNumber}
                                    onChange={restrictInputLengthForBankDetails(8, handleChange)}
                                    fullWidth
                                    error={hasTriedToSubmit && errors.accountNumber}
                                    data-testid="account-number"
                                  />
                                </BankInfoFieldWrapper>

                                <BankInfoFieldWrapper>
                                  <Field component={SortCodeField} />
                                </BankInfoFieldWrapper>
                              </Stack>
                            </FormContainer>

                            <div data-testid={'payment-details-accountOwner'}>
                              <Checkbox
                                id="accountOwner"
                                name="accountOwner"
                                label={
                                  <P>
                                    <Strong>{accountHolderConfirmation}</Strong>
                                  </P>
                                }
                                checked={values.accountOwner}
                                onChange={handleChange}
                                error={hasTriedToSubmit && errors.accountOwner}
                                data-testid="account-ownership-checkbox"
                              />
                            </div>
                          </Stack>
                          <AccordionWrapper>
                            <AccordionGroup>
                              <Accordion
                                title={'How we will process your payments'}
                                onClick={() => {
                                  mixpanel.track(MixpanelEvents.TOGGLE_PROCESS_PAYMENTS_ACCORDION, {
                                    accordionId: 'How we will process your payments',
                                  });
                                }}
                              >
                                <Stack spaceBetween={4}>
                                  <P>{paymentFlow}</P>
                                  <P>Payments are protected by {links.guarantee}.</P>
                                </Stack>
                              </Accordion>
                            </AccordionGroup>
                          </AccordionWrapper>
                          <AccordionWrapper>
                            <AccordionGroup>
                              <Accordion
                                title={'Direct Debit Guarantee'}
                                onClick={() => {
                                  mixpanel.track(MixpanelEvents.TOGGLE_DD_GUARANTEE_ACCORDION, {
                                    accordionId: 'Direct Debit Guarantee',
                                  });
                                }}
                              >
                                <Stack spaceBetween={8}>
                                  <Stack spaceBetween={2}>
                                    <Strong>Direct Debit Guarantee</Strong>
                                    <P>{directDebitGuarantee}</P>
                                  </Stack>
                                  <Stack spaceBetween={2}>
                                    <Strong>Direct Debit instructions</Strong>
                                    <P>{directDebitInstruction}</P>
                                  </Stack>
                                </Stack>
                              </Accordion>
                            </AccordionGroup>
                          </AccordionWrapper>
                          {epdCoverSelected && documentUrl && (
                            <AccordionWrapper>
                              <AccordionGroup>
                                <Accordion
                                  title={'Cancellation fees'}
                                  onClick={() => {
                                    mixpanel.track(
                                      MixpanelEvents.TOGGLE_CANCELLATION_FEES_ACCORDION,
                                      {
                                        accordionId: 'Cancellation fees',
                                      }
                                    );
                                  }}
                                >
                                  <Stack spaceBetween={8}>
                                    <Stack spaceBetween={2}>
                                      <Strong>
                                        Cancelling during the 14 day &quot;cooling-off period&quot;
                                      </Strong>
                                      <P>{cancellingDuringFourteenDaysPeriod}</P>
                                    </Stack>
                                    <Stack spaceBetween={2}>
                                      <Strong>
                                        Cancelling after the 14 day &quot;cooling-off period&quot;
                                      </Strong>
                                      <P>{cancellingAfterFouteenDaysPeriod}</P>
                                      <P>
                                        You’ll never be charged more than the remaining premium to
                                        be paid for the policy.
                                      </P>
                                      <P>
                                        Please see the{' '}
                                        <TextLink
                                          href={documentUrl[DocumentType.TERMS]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Terms
                                        </TextLink>{' '}
                                        for more details.
                                      </P>
                                    </Stack>
                                  </Stack>
                                </Accordion>
                              </AccordionGroup>
                            </AccordionWrapper>
                          )}
                          {!isAbs && documentUrl ? (
                            <StyledInfoNotification
                              id="cover-info"
                              title="Important information"
                              nonce={undefined}
                            >
                              <P>
                                Please read the{' '}
                                <TextLink
                                  href={documentUrl[DocumentType.IPID]}
                                  target="_blank"
                                  aria-label={links.ipid}
                                  rel="noopener noreferrer"
                                >
                                  Insurance Product Information Document (IPID)
                                </TextLink>{' '}
                                to understand what you are covered for and avoid surprises if you
                                claim.{' '}
                              </P>
                            </StyledInfoNotification>
                          ) : null}
                          <Stack spaceBetween={2}>
                            <Heading3>Please confirm:</Heading3>
                            <Checkbox
                              id="termsAndConditions"
                              name="termsAndConditions"
                              label={
                                <Stack spaceBetween={4}>
                                  <P>
                                    <Strong>
                                      I confirm that I have read, understood, and accepted the:
                                    </Strong>
                                    <Ul>
                                      {!isAbs && documentUrl && (
                                        <Li>
                                          <TextLink
                                            href={documentUrl[DocumentType.IPID]}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Insurance Product information document (IPID)
                                          </TextLink>
                                        </Li>
                                      )}
                                      {epdCoverSelected && documentUrl && (
                                        <Li>
                                          <TextLink
                                            href={documentUrl[DocumentType.TERMS]}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Terms
                                          </TextLink>
                                        </Li>
                                      )}
                                      {!epdCoverSelected && documentUrl && (
                                        <Li>
                                          <TextLink
                                            href={documentUrl[DocumentType.TERMS]}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Terms and conditions
                                          </TextLink>
                                        </Li>
                                      )}
                                      {documentUrl && (
                                        <Li>
                                          <TextLink
                                            href={documentUrl[DocumentType.PRIVACY]}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Distance marketing information
                                          </TextLink>
                                        </Li>
                                      )}
                                      <Li>
                                        <TextLink
                                          href={PRIVACY_POLICY}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Privacy Policy
                                        </TextLink>
                                      </Li>
                                    </Ul>
                                  </P>
                                  {!isAbs && (
                                    <P>
                                      <Strong>
                                        I wish to apply for {selectedCoverTypeText} with OVO, and i
                                        understand that:
                                      </Strong>
                                      <Ul>
                                        <Li>
                                          My policy will start once my application has been
                                          approved, which can take up to 3 working days.
                                        </Li>
                                        <Li>
                                          No claims can be made within the first 30 days of the
                                          policy, this is to prevent claims on pre-existing problems
                                          and to keep premiums low.
                                        </Li>
                                        <Li>
                                          The start date and payment details will be confirmed on
                                          the policy documentation.
                                        </Li>
                                      </Ul>
                                    </P>
                                  )}
                                </Stack>
                              }
                              checked={values.termsAndConditions}
                              onChange={handleChange}
                              error={hasTriedToSubmit && errors.termsAndConditions}
                              data-testid="termsAndConditions"
                            />

                            <Checkbox
                              id="paperless"
                              name="paperless"
                              label={
                                <P>
                                  <Strong>{receiveDocumentation}</Strong>
                                </P>
                              }
                              checked={values.paperless}
                              onChange={handleChange}
                              error={hasTriedToSubmit && errors.paperless}
                            />
                          </Stack>
                          <MarginDivider type={'differentiated'} />
                          <Stack spaceBetween={2} data-testid="policy-renewal">
                            {isAbs ? (
                              <>
                                <Heading3>{policyRenewal.title}</Heading3>
                                <P>{policyRenewal.contractInfo}</P>
                              </>
                            ) : (
                              <div data-testid="policy-renewal">
                                <Heading3>Policy renewal</Heading3>
                                <Margin vertical={4} />
                                <P>
                                  Please note we’ve set your plan to automatically renew. We’ll be
                                  in touch before the end of your contract.
                                </P>
                                <Margin vertical={4} />
                                <AccordionWrapper>
                                  <AccordionGroup>
                                    <Accordion
                                      title={'More about auto-renewal'}
                                      onClick={() => {
                                        mixpanel.track(
                                          MixpanelEvents.TOGGLE_AUTO_RENEWAL_ACCORDION,
                                          {
                                            accordionId: 'More information on auto-renewal',
                                          }
                                        );
                                      }}
                                    >
                                      <div data-testid="auto-renewal-accordion-body">
                                        <P>{autoRenewalAccordianText}</P>
                                        <P>
                                          If you decide not to auto-renew and we do not hear from
                                          you by the end of your contract, your cover will end.{' '}
                                        </P>
                                        <Checkbox
                                          name="autoRenewal"
                                          id="autoRenewal"
                                          data-testid="policy-renewal-checkbox"
                                          label={<P>I’m happy to auto renew</P>}
                                          checked={values.autoRenewal === AutoRenewalOption.YES}
                                          onChange={(e) => {
                                            setFieldValue(
                                              'autoRenewal',
                                              e.target.checked
                                                ? AutoRenewalOption.YES
                                                : AutoRenewalOption.NO
                                            );
                                          }}
                                        />
                                      </div>
                                    </Accordion>
                                  </AccordionGroup>
                                </AccordionWrapper>
                              </div>
                            )}
                          </Stack>
                          {isHomeRecover ? (
                            <>
                              <MarginDivider type={'differentiated'} />
                              <Stack spaceBetween={2}>
                                <Heading3>Home Recover</Heading3>
                                <Checkbox
                                  id="homeRecoverTerms"
                                  name="homeRecoverTerms"
                                  label={
                                    <P>
                                      <Strong>
                                        As part of Home Recover, I confirm that I have read,
                                        understood and accepted the{' '}
                                        <TextLink
                                          href={getEnergyCreditTermsURL()}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          repair terms and conditions
                                        </TextLink>{' '}
                                      </Strong>
                                    </P>
                                  }
                                  checked={values.homeRecoverTerms}
                                  onChange={handleChange}
                                  error={hasTriedToSubmit && errors.homeRecoverTerms}
                                />
                                {!isLoggedIn ? (
                                  <P>
                                    We&apos;ll ask you to make payment for your repair by card once
                                    you confirm your details on the next page.
                                  </P>
                                ) : null}
                              </Stack>
                            </>
                          ) : null}
                          <MarginDivider type={'differentiated'} />
                          <Stack spaceBetween={2}>
                            <Heading3>Keeping in touch</Heading3>

                            <P>
                              Want to be the first to hear about the latest offers and services from
                              OVO? Opt-in here
                            </P>
                            <Checkbox
                              id="email"
                              name="email"
                              label={<Strong>Email</Strong>}
                              checked={values.email}
                              onChange={handleChange}
                            />

                            <Checkbox
                              id="sms"
                              name="sms"
                              label={<Strong>SMS</Strong>}
                              checked={values.sms}
                              onChange={handleChange}
                            />
                          </Stack>
                          <MarginDivider type={'differentiated'} />
                          <P>
                            Please see our{' '}
                            <TextLink
                              href={
                                'https://www.ovobyus.com/m/128d9ac844ed663c/original/sr-ref-003-ohp-privacy-policy.pdf'
                              }
                              target="_blank"
                            >
                              Privacy Policy
                            </TextLink>{' '}
                            for further details. We’ll never share your data with 3rd parties for
                            marketing and we may still contact you via other channels, as outlined
                            in our Privacy Policy. At any point you can opt out of OVO
                            communications via your online account or by contacting 0330 102 8905
                            (Monday to Friday, 8am to 8pm, Saturday and Sunday, 8am to 5pm)
                          </P>
                        </Stack>

                        {!isMobile && (
                          <>
                            <MarginDivider type={'differentiated'} />
                            <BasketNavigationButtonsV2
                              isLoading={isSubmitting || signupComplete}
                              nextText={
                                isHomeRecover
                                  ? 'Go to Check Your Details'
                                  : 'Submit your application'
                              }
                              autoRenewal={values.autoRenewal}
                              backButtonConfig={{
                                text: backButtonText,
                                onClick: handleBackButtonClick,
                              }}
                            />
                          </>
                        )}
                      </CustomCard>
                    </BasicPage.Left>
                    <BasicPage.Right>
                      <>
                        <BasketV2
                          showExcessToggle={!isAbs && journey !== Journey.InsuranceHighExcess}
                          nextText={
                            isHomeRecover ? 'Check your details' : 'Submit your application'
                          }
                        />
                        {isMobile && (
                          <>
                            <Margin top={4} />
                            <BasketNavigationButtonsV2
                              isLoading={isSubmitting || signupComplete}
                              nextText={
                                isHomeRecover
                                  ? 'Go to Check Your Details'
                                  : 'Submit your application'
                              }
                              autoRenewal={values.autoRenewal}
                              backButtonConfig={{
                                text: backButtonText,
                                onClick: handleBackButtonClick,
                              }}
                            />
                          </>
                        )}
                      </>
                    </BasicPage.Right>
                  </BasicPage.Content>
                </Form>
              );
            }}
          </Formik>
        </BasicPage>
      )}
    </>
  );
};
