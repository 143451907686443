import { FormikErrors, FormikHelpers } from 'formik';
import { navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { withOptimizely, WithOptimizelyProps } from '@optimizely/react-sdk';
import { RouteComponentProps } from '@reach/router';
import { omitBy } from 'lodash';
import { Mixpanel } from 'mixpanel-browser';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { StatusType } from '@datadog/browser-logs';
import { ContextValue } from '@datadog/browser-core';

import { NewPaymentDetails as NewPaymentDetailsComponent } from './NewPaymentDetails';

import * as Logger from '@src/logging/logger';
import {
  ROUTE_CONFIRMATION,
  ROUTE_DETAILS,
  ROUTE_EPD_CONFIRMATION,
  ROUTE_EV_CONFIRMATION,
  ROUTE_HOME,
  ROUTE_HOME_RECOVER_CONFIRMATION,
  ROUTE_HOME_RECOVER_SUMMARY,
  ROUTE_SUMMARY,
} from '@constants/routes';
import { AutoRenewalOption, MixpanelEvents } from '@entities/enums';
import {
  creditTypeAtom,
  excessAtom,
  homePlanStoreAtom,
  isCreditEligibleAtom,
  MarketingPermissions,
  marketingPermissionsAtom,
  orderReferenceAtom,
  PaymentDetails,
  paymentDetailsAtom,
  personalDetailsAtom,
  SensitivePaymentDetails,
  sensitivePaymentDetailsAtom,
  signupCompleteAtom,
  utmTrackingAtom,
  store,
  journeyAtom,
  selectedProductAtom,
} from '@src/store/store';
import { useAuth } from '@hooks/useAuth';
import { useAppContext } from '@contexts/AppContext';
import { formatPaymentDetailsErrors } from '@utils/signupValidationHelper';
import { useSignupValidation } from '@hooks/useSignupValidation';
import { useSignupMutation } from '@hooks/mutations/useSignupMutation';
import { HttpErrorResponse } from '@services/HttpService';
import { isBrowser } from '@utils/isBrowser';
import { ModalContextProvider } from '@contexts/ModalContext';
import useEvCharger from '@hooks/useEvCharger';
import useEpdCover from '@hooks/useEpdCover';
import { Journey } from '@src/types/Journey';
import { useQuotesPlatform } from '@hooks/useQuotesPlatform';
import { ProductCode } from '@src/types/Quote';
import { createSignupDtoV2 } from '@src/factories/Signup/createSignupDtoV2';
import { createBillingInfo } from '@src/factories/Signup/SignupFactory';

export type FormData = PaymentDetails & SensitivePaymentDetails & MarketingPermissions;

interface Props extends RouteComponentProps, WithOptimizelyProps {
  mixpanel: Mixpanel;
}

const NewPaymentDetailsPageComponent: React.FC<Props> = ({ mixpanel, optimizely, location }) => {
  const { evChargerSelected } = useEvCharger();
  const { epdCoverSelected } = useEpdCover();
  const journey = useAtomValue(journeyAtom);
  const personalDetails = useAtomValue(personalDetailsAtom);
  const creditType = useAtomValue(creditTypeAtom);
  const isCreditEligible = useAtomValue(isCreditEligibleAtom);
  const utmTracking = useAtomValue(utmTrackingAtom);
  const [paymentDetails, setPaymentDetails] = useAtom(paymentDetailsAtom);
  const [sensitivePaymentDetails, setSensitivePaymentDetails] = useAtom(
    sensitivePaymentDetailsAtom
  );
  const [signupComplete, setSignupComplete] = useAtom(signupCompleteAtom);
  const [marketingPermissions, setMarketingPermissions] = useAtom(marketingPermissionsAtom);
  const setOrderReference = useSetAtom(orderReferenceAtom);

  const mixpanelRef = useRef(mixpanel);
  const optimizelyRef = useRef(optimizely);

  const { user } = useAuth();

  const formData = {
    ...paymentDetails,
    ...sensitivePaymentDetails,
    ...marketingPermissions,
    isHomeRecover: journey === Journey.HomeRecover,
  };

  const isLoggedIn = !!user?.accountId;
  const [isError, setIsError] = useState(false);
  const [badBankDetails, setBadBankDetails] = useState(false);

  const { promo } = useAppContext();

  const excess = useAtomValue(excessAtom);
  const selectedProduct = useAtomValue(selectedProductAtom);

  const { getQuoteByProductCode, getQuoteWrapperByProductCode } = useQuotesPlatform({ excess });

  const selectedQuote = selectedProduct && getQuoteByProductCode(selectedProduct);
  const selectedQuoteWrapper = selectedProduct && getQuoteWrapperByProductCode(selectedProduct);

  const { validateBillingInfo, validateSignupDto } = useSignupValidation();
  const { mutateAsync: signup } = useSignupMutation();

  const isAbs = selectedProduct === ProductCode.ABS || journey === Journey.Abs;
  const isHomeRecover =
    selectedProduct === ProductCode.HOME_RECOVER_COMPLETE || journey === Journey.HomeRecover;

  const validate = (values: FormData): FormikErrors<FormData> => {
    const cleanValues = omitBy(values, (value) => typeof value === 'string' && value === '');
    const billingInfo = createBillingInfo(
      {
        accountNumber: cleanValues.accountNumber,
        nameOnAccount: cleanValues.nameOnAccount,
        sortCode: cleanValues.sortCode,
      },
      cleanValues.accountOwner
    );
    const billingInfoResult = validateBillingInfo(billingInfo);

    const errors = billingInfoResult.errors
      ? formatPaymentDetailsErrors(billingInfoResult.errors)
      : {};

    if (!values.termsAndConditions) {
      errors.termsAndConditions = 'Please confirm you read and accept these documents';
    }

    if (!values.accountOwner) {
      errors.accountOwner = 'Please confirm you can authorise payments on this account';
    }

    if (isHomeRecover && !values.homeRecoverTerms) {
      errors.homeRecoverTerms = 'Please confirm you read and accept these documents';
    }

    return errors;
  };

  const submitApplication = async ({ setSubmitting }: FormikHelpers<FormData>) => {
    if (!selectedQuote) {
      setIsError(true);
      Logger.error('failed to find quote when submitting applications');
      return;
    }

    const homeplanStore = store.get(homePlanStoreAtom);
    const addressLine2 = homeplanStore.personalDetails.addressLine2;

    const signupDto = createSignupDtoV2({
      store: {
        ...homeplanStore,
        accountId: user?.accountId,
        personalDetails: {
          ...homeplanStore.personalDetails,
          addressLine2: addressLine2 === '' ? undefined : addressLine2,
        },
      },
      quote: selectedQuote,
      coverTypeId: 0,
    });

    const validation = validateSignupDto(signupDto);

    if (!validation.data) {
      Logger.error('Signup has failed', {
        errors: validation.errors?.map((error) => {
          return {
            property: error.schemaPath,
            errorMessage: error.message,
          };
        }),
        accountId: user?.accountId,
      });
      setIsError(true);
      return;
    }

    try {
      const response = await signup(validation.data);

      setIsError(false);
      setBadBankDetails(false);
      setOrderReference(response.id);
      setSignupComplete(true);

      mixpanel.track(MixpanelEvents.SIGNUP_SUCCESS, {
        productCode: selectedQuote.product,
        accountId: user?.accountId,
        excessAmount: selectedQuote.excess,
        price: selectedQuote.price.contract.monthly,
        promoCode: promo,
      });
      Logger.log(
        'Signup has been successful',
        {
          coverTypeId: 0,
          signupId: response.id,
          productName: selectedQuote.product,
          excess: selectedQuote.excess,
          accountId: user?.accountId,
          promo,
          utmTracking: utmTracking as ContextValue,
          isCreditEligible,
          creditType,
          isAbs: journey === Journey.Abs,
          is95Excess: journey === Journey.InsuranceHighExcess,
        },
        StatusType.info
      );

      if (evChargerSelected) {
        await navigate(ROUTE_EV_CONFIRMATION);
        return;
      }

      if (epdCoverSelected) {
        await navigate(ROUTE_EPD_CONFIRMATION);
        return;
      }

      await navigate(ROUTE_CONFIRMATION);
    } catch (error) {
      const event =
        error instanceof HttpErrorResponse && error.data?.errors?.BillingInfo
          ? MixpanelEvents.BAD_BANK_DETAILS_ERROR
          : MixpanelEvents.SIGNUP_ERROR;

      setIsError(event !== MixpanelEvents.BAD_BANK_DETAILS_ERROR);

      setBadBankDetails(event === MixpanelEvents.BAD_BANK_DETAILS_ERROR);
      Logger.error('Signup has failed', {
        errors: error instanceof HttpErrorResponse && error.data?.errors,
        accountId: user?.accountId,
      });
      mixpanel.track(event, {
        error,
        accountId: user?.accountId,
      });

      isBrowser() &&
        window.scrollTo({
          top: 0,
          left: 0,
        });

      setSubmitting(false);
    }
  };

  // onSubmit should be a synchronous function, although it can still handle nested asynchronous functions - https://github.com/jaredpalmer/formik/issues/2442#issuecomment-654590829
  const onSubmit = (values: FormData, formikHelpers: FormikHelpers<FormData>) => {
    const { nameOnAccount, accountNumber, sortCode, email, sms, phone, post, ...paymentDetails } =
      values;

    if (values.autoRenewal === AutoRenewalOption.YES) {
      optimizely?.track('UserOptsInToAutoRenewal');
    }

    setPaymentDetails(paymentDetails);
    setSensitivePaymentDetails({
      nameOnAccount,
      accountNumber,
      sortCode,
    });
    setMarketingPermissions({
      email,
      sms,
      post,
      phone,
    });

    mixpanel.track(MixpanelEvents.MARKETING_PREFERENCES_SELECTED, {
      email: email ? 'Yes' : 'No',
      sms: sms ? 'Yes' : 'No',
    });

    if (journey !== Journey.HomeRecover) {
      return submitApplication(formikHelpers);
    }

    navigate(journey === Journey.HomeRecover ? ROUTE_HOME_RECOVER_SUMMARY : ROUTE_SUMMARY);
    return;
  };

  useEffect(() => {
    const isPersonalDetailsEmpty = !Object.values(personalDetails).some(
      (personalDetailProperty) => personalDetailProperty !== null && personalDetailProperty !== ''
    );

    if (!personalDetails || isPersonalDetailsEmpty) {
      navigate(ROUTE_DETAILS, { replace: true });
    }
  }, [personalDetails]);

  useEffect(() => {
    if (signupComplete) {
      navigate(
        journey === Journey.HomeRecover ? ROUTE_HOME_RECOVER_CONFIRMATION : ROUTE_CONFIRMATION
      );
      return;
    }
    if (!selectedQuote) {
      navigate(ROUTE_HOME, { replace: true });
      return;
    }
  }, [journey, selectedQuote, signupComplete]);

  useEffect(() => {
    mixpanelRef.current.track(MixpanelEvents.PAGE_PAYMENT, {
      pageName: null,
    });
    optimizelyRef.current?.track(MixpanelEvents.PAGE_PAYMENT);
  }, []);

  return (
    <ModalContextProvider>
      <NewPaymentDetailsComponent
        onSubmit={onSubmit}
        isLoggedIn={isLoggedIn}
        validate={validate}
        formData={formData}
        location={location}
        error={isError}
        isHomeRecover={isHomeRecover}
        isAbs={isAbs}
        productName={selectedQuoteWrapper?.product.name}
        documents={selectedQuoteWrapper?.documents}
        badBankDetails={badBankDetails}
      />
    </ModalContextProvider>
  );
};
export const NewPaymentDetailsPage = withOptimizely<Props, null>(NewPaymentDetailsPageComponent);
