import { PageProps } from 'gatsby';
import React from 'react';
import { useDecision } from '@optimizely/react-sdk';

import { useCustomMixpanel } from '@services/Mixpanel';
import { PaymentDetailsPage } from '@src/modules';
import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { NewPaymentDetailsPage } from '@src/modules/PaymentDetails/NewPaymentDetailsPage';

const Payment = (props: PageProps) => {
  const mixpanel = useCustomMixpanel();
  const [{ enabled: isQuotesPlatform }] = useDecision(OptimizelyFeatureFlag.QUOTE_PLATFORM_ROLLOUT);

  if (isQuotesPlatform) {
    return <NewPaymentDetailsPage mixpanel={mixpanel} location={props.location} />;
  }

  return <PaymentDetailsPage {...props} mixpanel={mixpanel} />;
};

export default Payment;
